
import { ref, defineComponent } from 'vue'
import * as api from '../../utils/api'
import Menu from '../../components/menu/Admin.vue'
import HospitalData from '../../components/HospitalData.vue'
import moment from 'moment-timezone'
import { useDialog } from 'naive-ui'
moment.locale('ja')
moment.tz.setDefault('Asia/Tokyo')

export default defineComponent({
	components: {
		Menu,
		HospitalData,
	},
	data() {
		const dialog = useDialog()
		const errorDialog = (e: any, onPositiveClick: Function) =>
			dialog.error({
				title: 'Error',
				content: e.message || '不明なエラー',
				closable: false,
				positiveText: 'OK',
				onPositiveClick: () => onPositiveClick(),
			})
		return {
			loading: false,
			hospitals: [],
			changeLoading: false,
			errorDialog,
		}
	},
	mounted: function () {
		document.title = '病院一覧 - Vets Index'
		this.init()
	},
	methods: {
		init: async function () {
			try {
				this.loading = true
				const data = await api.get(`/v1/admin/hospital_list`)
				this.hospitals = data
				this.loading = false
			} catch (e: any) {
				this.loading = false
				this.errorDialog(e, () => true)
				console.error(e)
			}
		},
		getDate: function (unix: number) {
			return moment(new Date(unix * 1000)).format('YYYY年MM月DD日')
		},
		changeStatus: async function (id: string, status: string) {
			try {
				this.changeLoading = true
				const dataR = await api.post(`/v1/admin/approve/hospital`, { id, status })
				this.changeLoading = false
				this.init()
			} catch (e: any) {
				this.changeLoading = false
				this.errorDialog(e, () => true)
				console.error(e)
			}
		},
		invoice: function (id: string) {
			this.$router.push(`/admin/invoice/${id}`)
		}
	},
})
