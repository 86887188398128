
import { defineComponent } from 'vue'
import { bussnessType } from '../utils/const'
import moment from 'moment-timezone'
moment.locale('ja')
moment.tz.setDefault('Asia/Tokyo')

export default defineComponent({
	components: {
	},
	props: ['data'],
    methods: {
		getTypeJa: function (type: string) {
            const types = bussnessType()
			for (const w of types) {
				if (w.value === type) return w.label
			}
		},
    }
})
